







































































































































import { ApiResponse, Inventory } from '@/interfaces'
import Vue from 'vue'
import { mapState } from 'vuex'

interface SynthesisItem {
  place?: string
  location?: string
  count: number
  value1: string | null
  value2: string | null
  value3: string | null
}

interface Rubrique {
  label: string
  value: string
  children: Rubrique[]
}

export default Vue.extend({
  props: {
    inventory: {
      type: Object as () => Inventory,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      data: {
        locations: [],
        places: [],
        rubriqueKeys: [],
      },
      rubriques: [],
      label1Display: '-',
      label2Display: '-',
      label3Display: '-',
      label1Visible: false,
      label2Visible: false,
      label3Visible: false,
      label1: '',
      label2: '',
      label3: '',
      quickEditValue: '',
      visiblePlacePop: [] as boolean[],
      visibleLocationPop: [] as boolean[],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    inventory(newVal) {
      this.fetchProjectSynthesis(newVal.id)

      this.label1 = newVal.value1Label
      this.label1Display = newVal.value1Label ?? '-'
      this.label2 = newVal.value2Label
      this.label2Display = newVal.value2Label ?? '-'
      this.label3 = newVal.value3Label
      this.label3Display = newVal.value3Label ?? '-'
    },
    user(newVal) {
      this.rubriques = newVal.client.config.rubriques
    },
  },
  mounted() {
    if (this.user) {
      this.rubriques = this.user.client.config.rubriques
    }
  },
  methods: {
    fetchProjectSynthesis(inventoryId: number) {
      this.busy = true
      this.$api
        .get(`/inventory/${inventoryId}/synthesis`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.data = apiResponse.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnéee.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    getTotalCount(obj: SynthesisItem[], key = null): number {
      let count = 0

      if (key) {
        obj.forEach((item) => {
          count = count + parseInt(item[key] ?? 0)
        })
      } else {
        obj.forEach((item) => {
          count = count + item.count ?? 0
        })
      }

      return count
    },
    formatValue(value: number): string {
      if (isNaN(value) || value == null) {
        return '-'
      } else {
        return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value)
      }
    },
    getRubriqueRec(rubriques: Rubrique[], str: string): string {
      let rub = '-'
      rubriques.forEach((rubrique: Rubrique) => {
        if (rubrique.value == str) {
          rub = rubrique.label
        }
        if (rubrique.children && rub == '-') {
          rub = this.getRubriqueRec(rubrique.children, str)
        }
      })

      return rub
    },
    editLabels() {
      const data = {
        value1Label: this.label1,
        value2Label: this.label2,
        value3Label: this.label3,
      }

      this.label1Visible = false
      this.label2Visible = false
      this.label3Visible = false

      const label1 = this.label1
      const label2 = this.label2
      const label3 = this.label3

      this.label1Display = this.label1
      this.label2Display = this.label2
      this.label3Display = this.label3

      this.busy = true
      this.$api
        .put(`/inventory/${this.inventory.id}`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.label1 = apiResponse.data.inventory.value1Label
          this.label1Display = apiResponse.data.inventory.value1Label
          this.label2 = apiResponse.data.inventory.value2Label
          this.label2Display = apiResponse.data.inventory.value2Label
          this.label3 = apiResponse.data.inventory.value3Label
          this.label3Display = apiResponse.data.inventory.value3Label
          this.$emit('updateInventory', apiResponse.data.inventory)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })

          this.label1Display = label1
          this.label2Display = label2
          this.label3Display = label3
        })
        .finally(() => {
          this.busy = false
        })
    },
    quickEdit(column: string, search: string, index: number) {
      const data = {
        column,
        search,
        changeto: this.quickEditValue,
      }

      this.visiblePlacePop[index] = false
      this.visibleLocationPop[index] = false

      this.$api
        .post(`/inventory/${this.inventory.id}/items/values/rename`, data)
        .then(() => {
          if (this.inventory.id) {
            this.fetchProjectSynthesis(this.inventory.id)
          }

          this.$emit('fetchInventoryItems')
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
    },
    setQuickEditValue(str: string, type: string, index: number) {
      this.quickEditValue = str

      // Closing all popovers
      this.closePopovers()

      switch (type) {
        case 'place':
          this.visiblePlacePop[index] = true
          break

        case 'location':
          this.visibleLocationPop[index] = true
          break
      }
    },
    openPopover(property: string) {
      this.closePopovers()

      switch (property) {
        case 'label1Visible':
          this.label1Visible = !this.label1Visible
          break

        case 'label2Visible':
          this.label2Visible = !this.label2Visible
          break

        case 'label3Visible':
          this.label3Visible = !this.label3Visible
          break
      }
    },
    closePopovers() {
      this.label1Visible = false
      this.label2Visible = false
      this.label3Visible = false

      for (let i = 0; i < this.visiblePlacePop.length; i++) {
        this.$set(this.visiblePlacePop, i, false)
      }

      for (let j = 0; j < this.visibleLocationPop.length; j++) {
        this.$set(this.visibleLocationPop, j, false)
      }
    },
  },
})
