


































import { ApiResponse, Document, Project, Model, Contact } from '@/interfaces'
import Vue from 'vue'
import DocumentsUpload from '@/components/DocumentsUpload.vue'
import DocumentsList from '@/components/DocumentsList.vue'
import DocumentGeneration from '@/components/DocumentGeneration.vue'

interface EmolumentConfig {
  taux: number
  limit: number
}

interface EmolumentItem {
  average: Average
}

interface Average {
  value: number
  emoluments: number[]
}

interface Emoluments {
  config: EmolumentConfig[]
  total: EmolumentItem | null
  items: EmolumentItem[] | null
}

export default Vue.extend({
  components: { DocumentGeneration, DocumentsUpload, DocumentsList },
  props: {
    label1: {
      type: String,
      required: false,
      default: 'Estimation 1',
    },
    label2: {
      type: String,
      required: false,
      default: 'Estimation 2',
    },
    isJudiciary: {
      type: Boolean,
      required: false,
      default: false,
    },
    project: {
      type: Object as () => Project,
      required: false,
      default: {},
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      photos: true,
      method: 'average',
      showModal: false,
      emoluments: {
        total: null,
        items: null,
      } as Emoluments,
      tableData: [] as Record<string, string>[],
      documents: [] as Document[],
      models: [] as Model[],
      chosenModel: null as number | null,
      chosenEstimate: 3,
      docToUpdate: null as null | Document,
      projectRequerant: null as Contact | null,
      projectDebiteur: null as Contact | null,
      projectId: null as null | string,
      structureType: null as null | string,
    }
  },
  watch: {
    project(newVal) {
      if (newVal.id) {
        this.projectId = newVal.id.toString()
        this.structureType = newVal.structure?.type
        if (newVal?.inventory) {
          this.projectRequerant = newVal.inventory?.requerant
          if (this.projectRequerant?.id !== newVal.inventory?.debiteur?.id) {
            this.projectDebiteur = newVal.inventory?.debiteur
          }
        }
        //this.getDocuments()
      }
    },
    activeTab(newVal) {
      if (newVal === 'documents') {
        this.getDocuments()
      }
    },
  },
  mounted() {
    if (this.project) {
      this.projectId = this.project?.id?.toString() ?? ''
      this.structureType = this.project?.structure?.type ?? null
      if (this.project?.inventory) {
        this.projectRequerant = this.project?.inventory?.requerant ?? null
        if (this.projectRequerant?.id !== this.project?.inventory?.debiteur?.id) {
          this.projectDebiteur = this.project?.inventory?.debiteur ?? null
        }
      }
      //this.getDocuments()
    }
  },
  methods: {
    getCountDetail() {
      this.tableData = [] as Record<string, string>[]
      this.$api.get(`/emoluments/${this.$route.params.inventoryId}`).then((response) => {
        const apiResponse = response.data as ApiResponse

        this.emoluments = apiResponse.data

        this.emoluments.items?.forEach((item: EmolumentItem) => {
          this.tableData.push({
            col1: this.formatCurrency(item.average.value),
            col2: this.formatCurrency(item.average.emoluments[0]),
            col3: this.formatCurrency(item.average.emoluments[1]),
            col4: this.formatCurrency(item.average.emoluments[2]),
            col5: this.formatCurrency(item.average.emoluments[3]),
          })
        })

        this.showModal = true
      })
    },
    getSummaries(param: Record<string, string[]>) {
      const { columns } = param
      const sums = [] as string[]
      const total = this.emoluments.total as EmolumentItem

      columns.forEach((column: string, index: number) => {
        if (index === 0) {
          sums[index] = 'Totaux'
          return
        } else if (index === 1) {
          sums[index] = this.formatCurrency(total.average.value)
          return
        } else {
          sums[index] = this.formatCurrency(total.average.emoluments[index - 2])
        }
      })

      return sums
    },
    getIndex(scope: Record<string, number>) {
      return scope.$index + 1
    },
    formatCurrency(number: number): string {
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number).toString()
    },
    getLimit(index: number) {
      let lowLimit = '0'
      let highLimit = '0'
      let rate = ''

      if (this.emoluments.config) {
        lowLimit = index < 2 ? '0' : this.formatCurrency(this.emoluments.config[index - 2]?.limit)
        highLimit = this.formatCurrency(this.emoluments.config[index - 1]?.limit)
        rate = `${(this.emoluments.config[index - 1]?.taux * 100).toString()}%`
      }

      if (index > 3) {
        return `Au dessus de ${lowLimit} (${rate})`
      } else {
        return `De ${lowLimit} à ${highLimit} (${rate})`
      }
    },
    getDocuments() {
      if (this.projectId !== null && this.projectId !== '') {
        this.$api(`/project/${this.projectId}/documents`).then((response) => {
          const apiResponse = response.data as ApiResponse

          //this.updateDocuments(apiResponse.data)
          this.documents = apiResponse.data
        })
      }
    },
    // updateDocuments(documents: Document[]) {
    //   this.documents = documents
    // },
    refreshDocuments(documents: Document[]) {
      // Les appels sur chaque doc ne sont pas sensé renvoyer une liste mais le doc mis à jour
      // l'appel de refresh s'occupe donc de recharger la liste si besoin.
      //this.documents = documents
      this.getDocuments()
      this.docToUpdate = null
    },
    updateDoc(document: Document) {
      this.docToUpdate = document
      ;(this.$refs.uploadDoc as InstanceType<typeof DocumentsUpload>).addFile()
    },
  },
})
